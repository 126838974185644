import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Sidebar from '../../components/Sidebar';
import Filters from '../../components/Filters';
import { ventrisConfig } from '../../ventris.config';

type AdminHomeProps = {
};
  
function AdminHome({}: AdminHomeProps) {
  const [schemas, setSchemas] = useState([] as any);
  const [tests, setTests] = useState([] as any);
  const [filteredTests, setFilteredTests] = useState([] as any);
  const [users, setUsers] = useState([] as any);
  
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/schemas', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setSchemas(data);
    });

    fetch(ventrisConfig.url + '/api/tests', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setTests(data);
      setFilteredTests(data);
    });

    fetch(ventrisConfig.url + '/api/users', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setUsers(data);
    });
  }, []);

  const getTestData = () => {
    let testData: any = [];

    for (let i = 0; i < filteredTests.length; i++) {
      var t = filteredTests[i].created_at.replace("T", " ").replace("Z", " ").split(/[- :]/);
      var d = Date.UTC(t[0], t[1]-1, 1, 0, 0, 0);
      let found = false;

      for (let j = 0; j < testData.length; j++) {
        if (testData[j][0] == d) {
          testData[j][1]++;
          found = true;
          break;
        }
      }

      if (!found) {
        testData.push([d, 1]);
      }
    }

    return testData;
  }

  const chart1 = {
    chart: {
      type: 'spline'
    },
    title: {
      text: 'Test Volume'
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#562c83',
          fillColor: '#562c83',
          lineWidth: 1
        },
        color: '#562c83'
      }
    },
    series: [
      {
        data: getTestData()
      }
    ],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%Y'
      }
    }
  };

  const getUserData = () => {
    let userData: any = [];

    for (let i = 0; i < users.length; i++) {
      var t = users[i].created_at.replace("T", " ").replace("Z", " ").split(/[- :]/);
      var d = Date.UTC(t[0], t[1]-1, 1, 0, 0, 0);
      let found = false;

      for (let j = 0; j < userData.length; j++) {
        if (userData[j][0] == d) {
          userData[j][1]++;
          found = true;
          break;
        }
      }

      if (!found) {
        userData.push([d, 1]);
      }
    }

    return userData;
  }

  const chart2 = {
    chart: {
      type: 'spline'
    },
    title: {
      text: 'New Users Added'
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#562c83',
          fillColor: '#562c83',
          lineWidth: 1
        },
        color: '#562c83'
      }
    },
    series: [
      {
        data: getUserData()
      }
    ],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%Y'
      }
    }
  };

  const updateFilters = (test: string, clinician: string, status: string) => {
    let updatedFilteredTests: any = [];

    for (let i = 0; i < tests.length; i++) {
      if ((test === "All" || test === tests[i].name) && (clinician === "All" || clinician === tests[i].clinician) && (status === "All" || status === tests[i].status)) {
        updatedFilteredTests.push(tests[i]);
      }
    }

    setFilteredTests(updatedFilteredTests);
  };
  
  return (
    <Container fluid>
      <Sidebar portal="admin" page="home" />
      <Row>
        <Col className="main-content">
            <h2 className="page-header">Home</h2>
            <Filters updateFilters={updateFilters} />
            <Card className="content-card">
              <h3 className="content-card-header">DELV Analytics</h3>
              <Row>
                <Col>
                  <div className="analytics-chart">
                    <HighchartsReact highcharts={Highcharts} options={chart1} />
                  </div>
                </Col>
                <Col>
                  <div className="analytics-chart">
                    <HighchartsReact highcharts={Highcharts} options={chart2} />
                  </div>
                </Col>
              </Row>
            </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminHome;
