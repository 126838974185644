import { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { ventrisConfig } from '../ventris.config';

import ventris from '../assets/img/ventris.png';
import delv from '../assets/img/delv.png';

type LoginProps = {
    portal: string;
};
  
function Login({ portal }: LoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
        setValidated(true);
        setError("");
        fetch(ventrisConfig.url + '/api/auth', {
          method: 'post',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        }).then(function (response) {
          return response.json();
        }).then(async function (data) {
          if (data.success) {
            let token = data.token;
            sessionStorage.setItem("token", token);
            fetch(ventrisConfig.url + '/api/account', {
              method: 'get',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
              }
            }).then(function (response) {
              return response.json();
            }).then(async function (data) {
              let currentUser: any = data;
              console.log(currentUser);
              sessionStorage.setItem("portal", currentUser.role.toLowerCase());
              sessionStorage.setItem("current_user", JSON.stringify(currentUser));
              document.location.href = "/";
            });
          } else {
            setError("You entered an invalid email or password. Please try again!");
          }
        });    
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col className="full-height center">
            <div className="vertical-center center">
                <img src={ventris} alt="Ventris Learning Logo" className="logo" />
                <br /><br />
                <img src={delv} alt="DELV Logo" className="logo" />
                <br /><br /><br />
                <h2 className="purple">
                    { (portal.charAt(0).toUpperCase() + portal.slice(1)) } Portal
                </h2>
                <br />
                <Form className="login-form" noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control required type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Control required type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" className="btn-primary login-btn">Log In</Button>
                </Form>
                <br /><br />
                { error !== "" ? <Alert variant="danger" className="login-error">{error}</Alert> : null }
            </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
