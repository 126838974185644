import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { ventrisConfig } from '../ventris.config';

type FiltersProps = {
    updateFilters: any;
};
  
function Filters({ updateFilters }: FiltersProps) {
  const [test, setTest] = useState("All" as any);
  const [clinician, setClinician] = useState("All" as any);
  const [completionStatus, setCompletionStatus] = useState("All" as any);
  const [users, setUsers] = useState([] as any);
  const [schemas, setSchemas] = useState([] as any);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/users', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setUsers(data);
    });

    fetch(ventrisConfig.url + '/api/schemas', {
        method: 'get',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
        }
      }).then(function (response) {
        return response.json();
      }).then(async function (data) {
        setSchemas(data);
      });
  }, []);

  return (
    <div className="filters">
        <Container fluid>
            <Row>
                <Col className="filter-wrapper">
                    <Dropdown className="filter-dropdown">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span className="filter-value">Test: {test}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { setTest("All"); updateFilters("All", clinician, completionStatus); }}>All</Dropdown.Item>
                            {schemas.map((schema: any) =>
                                <Dropdown.Item onClick={() => { setTest(schema.name); updateFilters(schema.name, clinician, completionStatus); }}>{schema.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="filter-dropdown">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span className="filter-value">Clinician: {clinician}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { setClinician("All"); updateFilters(test, "All", completionStatus); }}>All</Dropdown.Item>
                            {users.map((user: any) =>
                                <Dropdown.Item onClick={() => { setClinician(user.name + " (" + user.email + ")"); updateFilters(test, user.name + " (" + user.email + ")", completionStatus); }}>{user.name + " (" + user.email + ")"}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="filter-dropdown">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span className="filter-value">Completion Status: {completionStatus}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { setCompletionStatus("All"); updateFilters(test, clinician, "All"); }}>All</Dropdown.Item>
                            <Dropdown.Item onClick={() => { setCompletionStatus("Incomplete"); updateFilters(test, clinician, "Incomplete"); }}>Incomplete</Dropdown.Item>
                            <Dropdown.Item onClick={() => { setCompletionStatus("Complete"); updateFilters(test, clinician, "Complete"); }}>Complete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Filters;
