import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import HomeIcon from '../icons/HomeIcon';
import TestIcon from '../icons/TestIcon';
import UserIcon from '../icons/UserIcon';

import ventris from '../assets/img/ventris.png';

type SidebarProps = {
    portal: string;
    page: string;
};
  
function Sidebar({ portal, page }: SidebarProps) {
  const [user, setUser] = useState([] as any);

  useEffect(() => {
    let currentUser = sessionStorage.getItem("current_user");
    setUser(JSON.parse(currentUser!));
  }, []);

  const logout = () => {
    sessionStorage.removeItem("token");
    document.location.href = "/";
  }

  return (
    <div className="sidebar">
        <Container fluid>
            <Row>
                <Col className="full-height center">
                    <img src={ventris} alt="Ventris Learning Logo" className="sidebar-logo" />
                    { portal === "admin" ?
                    <>
                        <Link to="/" className={page === "home" ? "sidebar-button-selected" : "sidebar-button"}>
                            <HomeIcon /> Home
                        </Link>
                        <Link to="/tests" className={page === "tests" ? "sidebar-button-selected" : "sidebar-button"}>
                            <TestIcon /> Manage Tests
                        </Link>
                        <Link to="/users" className={page === "users" ? "sidebar-button-selected" : "sidebar-button"}>
                            <UserIcon /> Manage Users
                        </Link>
                    </> :
                    <>
                        <Link to="/" className={page === "home" ? "sidebar-button-selected" : "sidebar-button"}>
                            <HomeIcon /> Home
                        </Link>
                        <Link to="/tests" className={page === "tests" ? "sidebar-button-selected" : "sidebar-button"}>
                            <TestIcon /> My Tests
                        </Link>
                    </> }
                    <div className="sidebar-bottom">
                        <div className="sidebar-bottom-username">{user.name}</div>
                        <div className="sidebar-bottom-email">{user.email}</div>
                        <Button variant="light" className="sidebar-bottom-logout-btn" onClick={logout}>Log Out</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Sidebar;
