import { useState, useEffect, useRef } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Sidebar from '../../components/Sidebar';
import { ventrisConfig } from '../../ventris.config';

import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';

type AdminUsersProps = {
};
  
function AdminUsers({}: AdminUsersProps) {
  const [user, setUser] = useState([] as any);
  const [users, setUsers] = useState([] as any);
  const [selectedUser, setSelectedUser] = useState({} as any);
  const [selectedUserId, setSelectedUserId] = useState("" as any);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [errorEdit, setErrorEdit] = useState("");
  const [validatedEdit, setValidatedEdit] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [errorCreate, setErrorCreate] = useState("");
  const [validatedCreate, setValidatedCreate] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");

  const editFormRef = useRef(null as any);
  const createFormRef = useRef(null as any);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    let currentUser = sessionStorage.getItem("current_user");
    setUser(JSON.parse(currentUser!));
    fetch(ventrisConfig.url + '/api/users', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setUsers(data);
    });
  }, []);

  const editUser = (user: any) => {
    setSelectedUser(user);
    setSelectedUserId(user.id);
    setEmail(user.email);
    setPassword("");
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setRole(user.role);
    setShowEditModal(true);
  }

  const handleEditSubmit = (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
        setValidatedEdit(true);
        setErrorEdit("");

        let token = sessionStorage.getItem("token");
        fetch(ventrisConfig.url + '/api/user/' + selectedUser.id, {
          method: 'put',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            email: email,
            password: password,
            name: firstName + " " + lastName,
            first_name: firstName,
            last_name: lastName,
            role: role
          })
        }).then(function (response) {
          return response.json();
        }).then(async function (data) {
          if (data.success) {
            fetch(ventrisConfig.url + '/api/users', {
              method: 'get',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
              }
            }).then(function (response) {
              return response.json();
            }).then(async function (data) {
              setUsers(data);
              setShowEditModal(false);
            });
          } else {
            setErrorEdit("An error occured while editing this user. Please try again!");
          }
        });
    }
  };

  const createUser = (user: any) => {
    setSelectedUser({});
    setSelectedUserId(-1);
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setRole("Clinician");
    setShowCreateModal(true);
  }

  const handleCreateSubmit = (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
        setValidatedCreate(true);
        setErrorCreate("");

        let token = sessionStorage.getItem("token");
        fetch(ventrisConfig.url + '/api/user', {
          method: 'post',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            email: email,
            password: password,
            name: firstName + " " + lastName,
            first_name: firstName,
            last_name: lastName,
            role: role
          })
        }).then(function (response) {
          return response.json();
        }).then(async function (data) {
          if (data.success) {
            fetch(ventrisConfig.url + '/api/users', {
              method: 'get',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
              }
            }).then(function (response) {
              return response.json();
            }).then(async function (data) {
              setUsers(data);
              setShowCreateModal(false);
            });
          } else {
            setErrorCreate("An error occured while creating this user. Please try again!");
          }
        });
    }
  };

  const deleteUser = (userId: any) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  }

  const actuallyDeleteUser = () => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/user/' + selectedUserId, {
      method: 'delete',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      fetch(ventrisConfig.url + '/api/users', {
        method: 'get',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
        }
      }).then(function (response) {
        return response.json();
      }).then(async function (data) {
        setUsers(data);
        setShowDeleteModal(false);
      });
    });
  }

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div>
        <Button variant="link" size="sm" onClick={() => editUser(row)}><img src={editIcon} alt="Edit User" /></Button>
        {row.id !== user.id ? <Button variant="link" size="sm" onClick={() => deleteUser(row.id)}><img src={deleteIcon} alt="Delete User" /></Button> : null}
      </div>
    );
  }

  const columns = [
    {
      dataField: 'email',
      text: 'Email Address',
      sort: true
    }, {
      dataField: 'first_name',
      text: 'First Name',
      sort: true
    }, {
      dataField: 'last_name',
      text: 'Last Name',
      sort: true
    }, {
      dataField: 'role',
      text: 'Role',
      sort: true
    }, {
      dataField: 'id',
      text: 'Actions',
      formatter: actionFormatter
    }
  ];

  return (
    <Container fluid>
      <Sidebar portal="admin" page="users" />
      <Row>
        <Col className="main-content">
            <h2 className="page-header">Manage Users</h2>
            <Card className="content-card">
              <BootstrapTable keyField='id' data={users} columns={columns} bordered={false} pagination={paginationFactory({})} />
            </Card>
            <div className="table-actions">
              <Button variant="primary" onClick={createUser}>Create User</Button>
            </div>
        </Col>
      </Row>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the selected user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => actuallyDeleteUser()}>Delete</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="edit-user-form" validated={validatedEdit} ref={editFormRef} onSubmit={handleEditSubmit}>
              <Form.Group className="mb-3" controlId="email">
                  <Form.Control required type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                  <Form.Control type="password" placeholder="Password (Optional)" value={password} onChange={(event) => setPassword(event.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="firstName">
                  <Form.Control required type="text" placeholder="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                  <Form.Control required type="text" placeholder="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="role">
                  <Form.Select required aria-label="Role" value={role} onChange={(event) => setRole(event.target.value)}>
                    <option value="Clinician">Clinician</option>
                    <option value="Admin">Admin</option>
                  </Form.Select>
              </Form.Group>
              <div className="modal-form-actions">
                <Button type="submit" className="btn-primary">Save</Button>
              </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="edit-user-form" validated={validatedCreate} ref={createFormRef} onSubmit={handleCreateSubmit}>
              <Form.Group className="mb-3" controlId="email">
                  <Form.Control required type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                  <Form.Control required type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="firstName">
                  <Form.Control required type="text" placeholder="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                  <Form.Control required type="text" placeholder="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                  <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="role">
                  <Form.Select required aria-label="Role" value={role} onChange={(event) => setRole(event.target.value)}>
                    <option value="Clinician">Clinician</option>
                    <option value="Admin">Admin</option>
                  </Form.Select>
              </Form.Group>
              <div className="modal-form-actions">
                <Button type="submit" className="btn-primary">Save</Button>
              </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default AdminUsers;
